import { useContext, createContext, useEffect, useState } from "react"
import { useLoader } from "./useLoader"
import { api } from '../services/api'
import { toast } from "react-toastify"
import { trimCampos } from "../util/funcoesPadrao"

const SistemaContext = createContext({})
const nomeStorage = (process.env.REACT_APP_LOCAL_STORAGE ?? 'projeto-espiga')
const userKey = '_' + nomeStorage + '_user'
const ultimoUserKey = '_' + nomeStorage + '_ultimo_user'

export function SistemaProvider({children}) {
  const [validToken, setValidToken] = useState(false)
  const [usuario, setUsuario] = useState({})
  const [empresa, setEmpresa] = useState({})
  const [empresas, setEmpresas] = useState([])
  const [menu, setMenu] = useState([])
  const { showLoader, hideLoader } = useLoader()

  async function login(dados){
    try {
      showLoader(true)
      dados = trimCampos(dados)

      const response = await api.post('?servico=getLogin', dados)
      
      if(response.data.situacao === "ok"){
        const user = response.data.obj
        //Busca menus 
        if(getMenus(user.id)){
          if(getPermissoesEmpresas(user.id, null)){
            setUsuario(user)
            // setEmpresa(empresa_logado)
            // setEmpresas(empresas)
            setValidToken(true)

            setUsuarioLocalStorage(user, empresa, empresas)
          }
        }
      } else {
        response.data.erro.forEach(e => {
          toast.error(e.msg)
        })
      }
    } catch (error) {
      toast.error('Falha ao realizar login')
    } finally {
      hideLoader()
      return
    }
  }
  
  function logout(){
    showLoader()
    setUsuario({})
    setEmpresa({})
    setEmpresas([])
    setMenu([])
    setValidToken(false)
    localStorage.removeItem(userKey)
    hideLoader()
  }
  
  async function validarToken(user, empresa_logado){
    try {
      showLoader()
      const response = await api.post('?servico=validaToken', { token: user.token })

      if(response.data.situacao === "ok"){
        if(getMenus(user.id)){
          if(getPermissoesEmpresas(user.id, empresa_logado)){
            setValidToken(true)
          } else {
            logout()
          }
        } else {
          logout()
        }
      } else {
        response.data.erro.forEach(e => {
          toast.error(e.msg)
        })
        logout()
      }
    } catch (error) {
      toast.error('Falha ao validar token do usuário')
      logout()
    } finally {
      hideLoader()
    }
  }

  function trocarEmpresa(id_empresa){
    setEmpresa(empresas.find(e => e.id === id_empresa))
    setUsuarioLocalStorage(usuario, empresas.find(e => e.id === id_empresa), empresas)
  }

  function atualizarDados(user){
    const u = {
      ...usuario, 
      usuario: user.usuario, 
      foto: (user.foto ?? usuario.foto)
    }
    setUsuario(u)
    if(!getPermissoesEmpresas(user.id, empresa)){
      toast.error('Falha ao atualizar lista de empresas')
    }
    setUsuarioLocalStorage(u, empresa, empresas)
  }

  function setUsuarioLocalStorage(u, e, es){
    localStorage.setItem(userKey, JSON.stringify({
      ...u, 
      empresa_logado: e,
      empresas: es, 
      id_empresa: e.id
    }))
    //Registra último login
    localStorage.setItem(ultimoUserKey, JSON.stringify(u.usuario))
  }

  async function getPermissoesEmpresas(id_usuario, empresa_logado){
    try {
      const response = await api.post('?servico=getPermissoesEmpresas', { id_usuario })
      
      if(response.data.situacao === "ok"){
        setEmpresas(response.data.arrayObj)
        //Verifica se ainda tem permissão da empresa logado
        if((empresa_logado)&&(response.data.arrayObj.find(e => e.id === empresa_logado.id))){
          setEmpresa(response.data.arrayObj.find(e => e.id === empresa_logado.id))
        } else {
          //Define empresa principal como logado
          setEmpresa(response.data.arrayObj.find(e => e.principal === true))
        }

        return true
      } else {
        response.data.erro.forEach(e => {
          toast.error(e.msg)
        })
        return false
      }
    } catch (error) {
      toast.error('Falha ao buscar permissões de empresas do usuário')
      return false
    }
  }

  async function getMenus(id_usuario){
    try {
      const response = await api.post('?servico=getPermissoes', { id_usuario })

      if(response.data.situacao === "ok"){
        setMenu(response.data.arrayObj)
        return true
      } else {
        response.data.erro.forEach(e => {
          toast.error(e.msg)
        })
        return false
      }
    } catch (error) {
      toast.error('Falha ao buscar permissões do usuário')
      return false
    }
  }

  useEffect(() => {
    const { empresas, empresa_logado, id_empresa, ...user} = JSON.parse(localStorage.getItem(userKey) ?? "{}")
    if(user.id){
      setUsuario(user)
      // setEmpresa(empresa_logado)
      // setEmpresas(empresas)
      validarToken(user, empresa_logado)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SistemaContext.Provider value={{ 
      usuario, 
      empresa, 
      empresas, 
      validToken,
      menu, 
      login, 
      logout, 
      validarToken, 
      trocarEmpresa,
      atualizarDados
    }}>
      {children}
    </SistemaContext.Provider>
  )
}

export function useSistema() {
    const context = useContext(SistemaContext)

    return context;
}